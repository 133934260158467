import { render, staticRenderFns } from "./boxcontainer.vue?vue&type=template&id=edfc8180&scoped=true"
import script from "./boxcontainer.vue?vue&type=script&lang=js"
export * from "./boxcontainer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "edfc8180",
  null
  
)

export default component.exports