<template>
    <div class="home">
        <navbar :menulist="$store.state.mainmenu" />

        <div class="row mx-auto pt-2">
            <div class="col-lg-12 col-md-12 col-xs-12 ml-2">
                <button type="button" class="btn btn-outline-dark btn-sm" @click="gotoBack">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16" style="vertical-align: sub">
                        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                    </svg>
                    {{$t('Indietro')}}
                </button>
            </div>
        </div>



        <!-- RIGA PER INPUT, ICONA CARRELLO -->
        <div v-if="showprods || showscheda" class="row mx-auto d-flex justify-content-center pb-4">
            <!-- <div class="col-lg-8 col-md-8 col-sm-10 col-xs-12">
                <div class="input-group"> -->
                    <button type="button" class="btn btn-outline-primary pl-2 pr-1" @click="gotoCarrello2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cart4" viewbox="0 0 16 16">
                            <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
                        </svg>
                        <span v-if="totqty > 0" class="badge badge-pill badge-warning badge-cart align-top">{{ totqty }}</span>
                    </button>
                <!-- </div>
            </div> -->
        </div>

        <div class="row mx-auto" v-if="isLoading">
            <img class="loadingImg" :src="loading" alt="caricamento...">
        </div>

        <!-- FINE PRIMA RIGA DI PRODOTTO -->

        <div v-if="showscheda">
            <shopproduct
                :prod="prod" :taglie="taglie || []"
                :numeri="numeri || []"
                @gotoCarrello="gotoCarrello" @addedToCart="showDone = true"
            ></shopproduct>
        </div>

        <div v-if="showcarrello">
            <!-- <shopcart v-bind:imgs="imgs" :shoppers="shoppers" :ricamo="ricamo"></shopcart> -->
            <shopcart v-bind:imgs="imgs" :shoppers="shoppers"></shopcart>
        </div>

        <!-- aggiunto al carrello -->
        <div v-show="showDone" class="modal fade show text-center" :class="{'show': showDone}">
            <div class="modal-dialog modal-dialog-centered modal-sm">
                <div class="modal-content modal-md">
                    <div class="modal-body">
                        <svg class="check-icon-done" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"/>
                        </svg>
                        <p>{{$t('AggiuntoCarrello')}}</p>
                        <button class="btn btn-light" @click="showDone = false">OK</button>
                    </div>
                </div>
            </div>
        </div>


        <footerline />
    </div>
</template>

<script>
    // @ is an alias to /src
    import navbar      from '@/components/navbar.vue'
    import footerline  from '@/components/footerline.vue'
    import shopproduct from '@/components/shopproduct.vue'
    import shopcart    from '@/components/shopcart.vue'

    const noimgsrc = require('../assets/no-image-found-ecommerce.png'),
          loading  = require('../assets/loading-dots.gif')

    export default {
        name: 'EcommerceShop',
        props: {
            productcode: String
        },
        components: {
            navbar,
            footerline,
            shopproduct,
            shopcart
        },
        data: function() {
            return {
                showprods:    true,
                showscheda:   false,
                showcarrello: false,
                prodSelected: {},
                shoppers: [],
                ricamo: {},

                // images
                noimage: noimgsrc,
                loading: loading,
                imgs: {},

                isLoading: false,
                showDone:  false,

                prod: {},
                taglie: [],
                numeri: []
            }
        },
        computed: {
            totqty: function() {
                var t = 0

                if (this.$store.state.carrelloEcommerce.length) this.$store.state.carrelloEcommerce.forEach(p => t += p.qty)
                
                return t
            }
        },
        mounted: async function() {
            this.getCarrello()
            
            let allProds = this.$store.getters.allProds;

            if(allProds.length == 0){
                let resp = await this.$store.dispatch('getProdsEcommerce');
                allProds = resp;
            }
            this.shoppers = this.$store.getters.shoppers;
            console.log('Product Code:', this.productcode,allProds);
            this.prod = allProds.find((p) => p.ProductCode == this.productcode);

            this.taglie = this.prod.taglie;
            this.numeri = this.prod.taglie;

            if(this.prod)
                this.showscheda = true;
        },
        methods: {
            // go to
            gotoBack: function() {
                this.prodSelected = {}
                if (this.showprods) this.$router.push({ name: 'EcommerceShop' })
                if (this.showscheda) {
                    this.showprods    = true
                    this.showscheda   = false
                    this.showcarrello = false
                }
                if (this.showcarrello) {
                    this.showcarrello = false

                    if (Object.keys(this.prodSelected).length === 0) {
                        this.showscheda = false
                        this.showprods  = true
                    } else {
                        this.showscheda = true
                        this.showprods  = false
                    }

                    window.location.reload()
                }
            },
            gotoCarrello: function(prod) {
                console.log('gotoCarrello')
                this.$store.dispatch('addProd', prod)
                this.showprods    = false
                this.showscheda   = false
                this.showcarrello = true
            },
            gotoCarrello2: function() {
                this.prodSelected = {}
                this.isLoading    = false
                this.showprods    = false
                this.showscheda   = false
                this.showcarrello = true
            },
            getCarrello: function() {
                this.$store.dispatch('recuperaCarrello')
            },
        }
    }
</script>

<style>
    .bordo-oro { border: 1px solid #d29500 !important; }

    .grigliaCommerceDiv2 { height: 161px; }

    .menuOpen {
        transform: translate3d(0px, 38px, 0px);
        top: 0px;
    }

    .badge-cart {
        font-size: .46rem;
        right: 4px;
    }

    .loadingImg {
        height: 100px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .check-icon-done { height: 100px; }

    .dropdown-menu .dropdown-submenu {
        left: 100%;
        top: -3px;
    }

    .dropdown-menu > li:hover > .dropdown-submenu { display: block; }

    .show > .btn-primary.dropdown-toggle {
        background-color: #d29500 !important;
        border-color: #d29500 !important;
    }

    .disabled{
        pointer-events: none;
        opacity: 0.4;
    }
</style>