/* eslint-disable no-console */

import axios from 'axios'
import jwt from 'jsonwebtoken'

/*
const apiClient = axios.create({
  //  baseURL: `http://localhost:3000`,
  withCredentials: false, // This is the default
})
*/

export default {
  secret: 'e2ffccce2a69190b8f3f16896b2cadeae380caaaae7e7fac2f31a83bd55757c5',

  getKey(filename) {
    //console.log('getkey:'+filename);

    this.secret='e2ffccce2a69190b8f3f16896b2cadeae380caaaae7e7fac2f31a83bd55757c5';

    console.log('getKey in apiclient: ha axios.get senza catch su errore')
    return axios.get(filename);
  },
  
  register(con, data) {
    var self=this;
    return new Promise(function(resolve,reject) {
      console.log('manda la query');
      var url = "./register"; // state.connection.mytoken
      var payload = {
        // token: con.mytoken,
        loginData: data
      }
      // console.log(payload);

      var jwtpayload = jwt.sign( payload, self.secret ); // con.key, { algorithm: 'RS256' });

      axios.post(url, { payload: jwtpayload } ).then(function(resp) {
        console.log('ricevuto');
        var data = resp.data;
        resolve(data);
      }).catch(function(err) {
        console.log('problemi:')
        console.log(err)
        console.log('problemi:')
        console.log(err.response.data)
        reject(err);
      });
        
    });
  },

  getprofile(con, data) {
    var self=this;
    return new Promise(function(resolve,reject) {
      console.log('manda la query');
      var url = "./getprofile"; // state.connection.mytoken
      var payload = {
        token: con.token,
        loginData: data
      }
      //console.log(payload);

      var jwtpayload = jwt.sign( payload, self.secret ); // con.key, { algorithm: 'RS256' });

      axios.post(url, { payload: jwtpayload } ).then(function(resp) {
        console.log('ricevuto');
        var data = resp.data;
        resolve(data);
      }).catch(function(err) {
        console.log('problemi:')
        console.log(err)
        console.log('problemi:')
        console.log(err.response.data)
        reject(err);
      });
        
    });
  },

  printonbackend(con, data) {
    console.log('con, data del printonbackend')
    console.log(con, data)
    var self=this;
    return new Promise(function(resolve,reject) {
      console.log('manda printonbackend');
      var url = "./printonbackend"; // state.connection.mytoken
      var payload = {
        token: con.token,
        logData: data,
      }
      console.log('payload',payload);
      var options= { };
      var jwtpayload = jwt.sign( payload, self.secret ); // con.key, { algorithm: 'RS256' });

      console.log('url', url)
      console.log('jwtpayload', jwtpayload)
      console.log('options', options)
      axios.post(url, { payload: jwtpayload }, options )
      .then(function() {
        console.log('axios.post per apiclient.printonbackend')
      }).catch(function(err) {
        console.log('PRINTONBACKEND HA DATO ERRORE')
        console.log('problemi axios.post per apiclient.printonbackend')
        console.log(err)
        console.log(err.response)
        console.log('token: ', con.token)
        reject(err);
      });
        
    });
  },

  getcandidatura(con, data, cb) {
    console.log('con, data, cb del apiclient.getcandidatura')
    console.log(con, data, cb)
    var self=this;
    return new Promise(function(resolve,reject) {
      console.log('manda getcandidatura');
      var url = "./getcandidatura"; // state.connection.mytoken
      var payload = {
        token: con.token,
        loginData: data,
      }
      console.log('payload',payload);
      var options= { };
      if (cb) {
        options.onUploadProgress=cb;
      }
      var jwtpayload = jwt.sign( payload, self.secret ); // con.key, { algorithm: 'RS256' });

      console.log('url', url)
      console.log('jwtpayload', jwtpayload)
      console.log('options', options)
      axios.post(url, { payload: jwtpayload }, options )
      .then(function(resp) {
        console.log('axios.post per apiclient.getcandidatura')
        resolve(resp.data);
      }).catch(function(err) {
        console.log('GETCANDIDATURA HA DATO ERRORE')
        console.log('problemi axios.post per apiclient.getcandidatura')
        console.log(err)
        console.log(err.response)
        console.log('token: ', con.token)
        reject(err);
      });
        
    });
  },

  login(con, data) {
    var self=this;
    return new Promise(function(resolve,reject) {
      console.log('manda la query');
      var url = "./me"; // state.connection.mytoken
      var payload = {
        // token: con.mytoken,
        loginData: data
      }
      // console.log(payload);

      var jwtpayload = jwt.sign( payload, self.secret ); // con.key, { algorithm: 'RS256' });

      axios.post(url, { payload: jwtpayload } ).then(function(resp) {
        console.log('ricevuto');
        var data = resp.data;
        resolve(data);
      }).catch(function(err) {
        console.log('problemi:')
        console.log(err)
        console.log('problemi:')
        console.log(err.response.data)
        reject(err);
      });
        
    });
  },

  loginbyid(con, data) {
    var self=this;
    return new Promise(function(resolve,reject) {
      console.log('manda la query');
      var url = "./myid"; // state.connection.mytoken
      var payload = {
        // token: con.mytoken,
        loginData: data
      }
      // console.log(payload);

      var jwtpayload = jwt.sign( payload, self.secret ); // con.key, { algorithm: 'RS256' });

      axios.post(url, { payload: jwtpayload } ).then(function(resp) {
        console.log('ricevuto');
        var data = resp.data;
        resolve(data);
      }).catch(function(err) {
        console.log('problemi:')
        console.log(err)
        console.log('problemi:')
        console.log(err.response.data)
        reject(err);
      });
        
    });
  },

  query(con, soql) {
    var self=this;
    return new Promise(function(resolve,reject) {
      console.log('manda la query');
      var url = "./query"; // state.connection.mytoken
      var payload = {
        token: con.token,
        query: soql
      }
      // console.log(payload);

      var jwtpayload = jwt.sign( payload, self.secret ); // con.key, { algorithm: 'RS256' });

      axios.post(url, { payload: jwtpayload } ).then(function(resp) {
        console.log('ricevuto');
        var data = resp.data;
        if (data.records) {
          resolve(data.records);
        }
      }).catch(function(err) {
        console.log('problemi:')
        console.log(err)
        console.log('problemi:')
        console.log(err.response.data)
        reject(err);
      });
        
    });
  },

  getprefissi() {
    return new Promise(function(resolve,reject) {
      console.log('richiedi i prefissi');
      var url = "./getprefissi";

      axios.get(url).then(function(resp) {
        console.log('ricevuto');
        var data = resp.data;
        if (data.records) {
          resolve(data.records);
        }
      }).catch(function(err) {
        console.log('problemi:')
        console.log(err)
        reject(err);
      });
        
    });
  },

  subscribePush(subscription){
    return new Promise(function(resolve, reject){
      console.log("manda subscribe");
      var url = "./subscribe";

      var config = { 
        headers: { 
          "Content-Type": "application/json"
        }
      };

      axios.post(url, subscription, config).then(function(resp){
        console.log('ricevuta post subscribe', resp);
        resolve(resp);
      }).catch(function(err){
        console.log("errore subscribe a notifiche push");
        console.log(err);
        reject(err);
      })

    })
  },

  update(con, object, id, body) {
    var self=this;
    return new Promise(function(resolve,reject) {
      console.log('manda un update');
      var url = "./update"; 
      var payload = {
        token: con.token,
        payload: { object: object, id: id, body: body }
      }
       console.log(payload);

      var jwtpayload = jwt.sign( payload, self.secret ); // con.key, { algorithm: 'RS256' });

      axios.post(url,  { payload: jwtpayload }  ).then(function(resp) {
        console.log('ricevuto');
        console.log(resp);
        resolve(resp);
      }).catch(function(err) {
        console.log('problemi:')
        console.log(err)
        console.log('problemi:')
        console.log(err.response.data)
        reject(err);
      });
        
    });
  },

/*
  getEvents() {
    return apiClient.get('/events')
  },
  checklogin(con) {
    console.log('checklogin:')
    var self = this;
    return new Promise(function(resolve, reject) {
      jwt.verify(con.mytokenizedid, self.secret, function(err, decoded) {
        if (err) { // do relogin
          console.log('do relogin:')
          self.login(con.email, con.myid_token).then(function(data) {
            console.log('relogin ok:' + data);
            con.commit('SET_CONNECTION_TOKEN', data);
            resolve(data);
          }).catch(function(err) {
            reject(err);
          })
        } else
          decoded; // lint
          var o = {
            mytokenizedid: con.mytokenizedid, // jwt del mio payload
            mytoken: con.token
          }
        resolve(o); // sostanzialmente inutile
      });
    })
  },
  getTasks(con, id) {
    var self = this;
    return new Promise(function(resolve, reject) {
      self.checklogin(con).then(function() {
        var url = "./query?q=";
        var soql = "SELECT  Id, Subject, Description, CreatedDate FROM Task where WhatId='" + id + "' order by CreatedDate desc";
        console.log(soql);
        axios.get(url + soql).then(function(resp) {
          var data = resp.data;
          resolve(data);
        }).catch(function(err) {
          reject(err);
        });

      }).catch(function(err) {
        reject(err);
      });

    });
  }
  */
}
