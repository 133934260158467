<template>
    <div class="back">
        <div class="isola" ref="container">
            <button type="button" class="close imgClose" aria-label="Close" @click="closeMe">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="border">
                <img 
                    :src="image" 
                    class="bigImage" 
                    @load="handleImageLoad"
                    ref="imageEl"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'imgView',
    props: ['image'],
    methods: {
        closeMe() {
            this.$emit('update-value')
        },
        handleImageLoad(event) {
            const img = event.target
            const container = this.$refs.container
            const aspectRatio = img.naturalWidth / img.naturalHeight
            
            // Imposta l'aspect ratio del contenitore
            container.style.aspectRatio = `${img.naturalWidth} / ${img.naturalHeight}`
            
            // Regola le dimensioni massime in base all'orientamento
            const isLandscape = aspectRatio > 1
            container.style.maxWidth = isLandscape ? '90vw' : 'min(90vw, 90vh * ${aspectRatio})'
            container.style.maxHeight = isLandscape ? 'min(90vh, 90vw / ${aspectRatio})' : '90vh'
        }
    }
}
</script>

<style>
.back {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1999;
}

.isola {
    position: relative;
    background: white;
    border-radius: 15px;
    margin: auto;
    padding: 40px 20px 20px;
    box-sizing: border-box;
    width: auto;
    height: auto;
    max-width: 90vw;
    max-height: 90vh;
}

.border {
    border: 3px solid #e0e0e0;
    border-radius: 12px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bigImage {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    object-fit: contain;
    padding: 10px;
}

.imgClose {
    position: absolute;
    top: 4px;
    right: 15px;
    font-size: 2rem;
    z-index: 2000;
    color: #666;
    opacity: 0.8;
    transition: opacity 0.2s;
}

.imgClose:hover {
    opacity: 1;
}
</style>