<template>

  <div v-if="show" style="display:block" class="modal fade" v-bind:class="{'show': show}" id="uploadDocument" tabindex="-1" role="dialog" aria-labelledby="uploadDocument" >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="uploadDocument">{{$t('CaricaDocumento')}}</h5>
          <button type="button" class="close" aria-label="Close"  v-on:click.prevent="$emit('cancel')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <label>{{$t('ScegliFile')}}
            <input type="file" id="file" multiple="true" ref="file" v-on:change="fileUpload" name="File"/>
          </label>
          <div v-if="submitOk">
            <!-- <label>{{$t('Filecaricare')}}</label><br/>{{this.myfile.name}}<br/> -->
            <label>{{$t('Dimensioni')}}</label>{{this.myfileMB}} MB<br/>
            <div v-if="erroredimensioni" class="alert alert-danger" role="alert">{{$t('DimensioniEccessive')}}</div>
            <div class="progress">
              <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" v-bind:aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100" v-bind:style="'width: '+progress+'%'"></div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button v-if="uploading" class="btn btn-primary" type="button" disabled>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="sr-only">Loading...</span>
          </button>          
          <button v-if="!uploading" type="button" class="btn btn-primary" v-on:click.prevent="doUpload" v-bind:disabled="!submitOk || erroredimensioni">{{$t('Carica')}}</button>
          <button v-if="!uploading" type="button" class="btn btn-secondary" v-on:click.prevent="$emit('cancel')" >{{$t('Annulla')}}</button>
        </div>
      </div>
    </div>
    <div v-if="show" style="z-index:-1" class="modal-backdrop fade show"></div>
  </div>

</template>

<script>
  import apiclient from '@/apiclient.js'

  export default {
    name: 'modalupload',
    props: {
      show: Boolean,
      id: String
    },
    data: function () {
      return {
        submitOk: false,
        myfile: [],
        fileData: [],
        progress: 0,
        uploading: false,
        myfileMB: 0
      }
    },
    computed: {
      erroredimensioni: function() {
        if (!this.myfile) return false;
        for(var i = 0; i < this.myfile.size; i++){
            if(this.myfile[i].size > 5242880) return true;
        }
        return false;
      },
    },
    methods: {
      fileUpload: function() {
        if (this.$refs.file.files.length>0) {
          this.submitOk=true;
          this.myfile=Array.from(this.$refs.file.files);
          console.log(this.myfile);
            var self = this;
            this.myfile.forEach(function (d) {
                if(self.myfile.length > 1) 
                  self.myfileMB = self.myfileMB < (d.size / 1000000) ? d.size / 1000000 : self.myfileMB;
                else
                  self.myfileMB = d.size / 1000000;
                
                const reader = new FileReader();
                reader.onload = self.doReadFile
                reader.readAsDataURL(d);
        });

        }
      },
      uploadProgress: function(ev) {
        console.log('uploadProgress');
        console.log(ev);
        this.progress = ev.loaded / ev.total * 100;        
      },
      doReadFile: function (e) {
        console.log('doReadFile modaluploadMulti');
        console.log(e);
        this.fileData.push(e.target.result.split(',')[1]);
      },
      doUpload: function() {
        this.uploading=true;
        var self=this;
        this.$nextTick(function () {
            self.$store.dispatch('loginbyid').then(function() {
            console.log('doUpload');
            self.fileData.forEach(function (d,i) {
                var data={ username: self.$store.state.connection.username, callname:'uploadDocument', crypt: self.$store.state.connection.userdata,
                    parentId: self.id,  filename: self.myfile[i].name,  payload: d,
                };
                apiclient.getcandidatura( self.$store.state.connection, data, self.uploadProgress)
                .then( function(resp) {
                console.log('doUpload:');
                console.log(resp)
                self.$emit('submit', resp);
                self.myfile={};
                self.fileData=[];
                self.progress=0;
                self.uploading=false;
                }).catch(err => {
                  console.log('err', err)
                }); 
            });
          });
        });
      }
    }
  }
</script>

<style scoped>
</style>