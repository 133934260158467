<template>
  <div class="home">
    <navbar v-bind:menulist="$store.state.mainmenu" ></navbar>
    <herobig v-bind:title="$t('title')"></herobig>
    
    <h2 class="welcome" v-if="!reg">Scansiona il qrcode</h2>
    <h2 class="welcome" v-if="reg">{{ msg }}</h2>
    
    <boxcontainer v-if="$store.getters.enablePresenze">
      <div v-if="!cameraok && !reg">
        Videocamera in corso di attivazione...
      </div>
      <div v-if="detected && !reg">
        Presenza registrata: Aula {{ aulaId }}<br/>
        Procedura terminata, chiusura in corso...
      </div>
      <div v-if="showCamera">
        <qrcode-stream v-on:detect="onDetect" v-on:init="initCamera" v-if="!detected" ></qrcode-stream>
      </div>
    </boxcontainer>
    
    <footerline></footerline>
    
  </div>
</template>

<script>
// @ is an alias to /src
import navbar from '@/components/navbar.vue'
import herobig from '@/components/herobig.vue'
import boxcontainer from '@/components/boxcontainer.vue'
import footerline from '@/components/footerline.vue'
import { QrcodeStream } from 'vue-qrcode-reader'
import apiclient from '../apiclient.js'
//import moment from 'moment'

export default {
  async mounted(){
    await this.checkLastRecordedCheckin();
  },
  name: 'Presenze',
  components: {
    navbar,
    herobig,
    boxcontainer,
    footerline,
    QrcodeStream
  },
  computed: {
    
  },
  data: function () {
    return {
      aulaId:'',
      showCamera: false,
      cameraok:false,
      detected:false,
      detectedcode:'',
      msg:'',
      reg: false
    };
  },
  
  methods: {
    initCamera: function(camera) {
      console.log('initCamera');
      
      camera.then( () =>{
        this.cameraok=true;
        this.showCamera = true;
      }).catch( (error) => {
        console.error(error);
        alert(error.name);
      })
    },

    onDetect: function(e) {
      console.log('detected',e);
      this.showCamera = false;
      e.then(resp => {
        console.log(resp);
        this.registraPresenza(resp.content);
      })
    },

    registraPresenza: function(code) {
      let code2=code.split(';');
      if(code2.length>1) { // otp;aula
        this.detectedcode=code;
        this.aulaId=code2[1];
        this.doRegistra(code2[1], code2[0]);
      } else {
        this.showCamera = true;
      }
    },
    
    doRegistra:function(aulaId, otp){
      var self=this;
      var idOppty = ''
      if(this.$store.state.opptyCorso) idOppty = this.$store.state.opptyCorso.Id
      var data = {
        callname:'saveCheckin', 
        username: this.$store.state.connection.username, 
        crypt: this.$store.state.connection.userdata, 
        aulaId: aulaId, 
        totp: otp,
        idOppty: idOppty
      };
      apiclient.getcandidatura( this.$store.state.connection, data).then( function(resp) {
        console.log(resp);
        self.detected=true;
        setTimeout(() => {
          window.location='/#/didattica';
        }, 5000)
      });
    },
    
    checkLastRecordedCheckin:function(){
      console.log("STO CONTROLLANDO L'ULTIMA REGISTRAZIONE")
      const self=this;
      //var data={callname:'lastRecordedCheckin', username: this.$store.state.connection.username, crypt: this.$store.state.connection.userdata};
      //apiclient.getcandidatura( this.$store.state.connection, data).then( function(resp) {
      
      return new Promise(function(resolve) {
        self.$store.dispatch('lastRecordedCheckin').then((resp) => {  
          console.log(resp);

          if(resp == 'registrato'){
            self.reg = true;
            self.cameraok = false;
            self.msg = "Ti sei già registrato per questa ora";
            self.detected= true;
          } else {
            self.showCamera = true;
          }
          
          return resolve();
        });
      })
    },
    
  }
}
</script>

<style scoped>

</style>