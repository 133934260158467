<template>
    <div class="home">
        <!-- idea di carrello -->

        <div class="container mt-1 p-3 rounded cart">
            <div class="d-flex flex-wrap justify-content-between">
                <div class="col mb-3">
                    <div>
                        <div class="border-bottom mb-3">
                            <h3>
                                <i class="bi bi-cart-fill"></i>
                                <span class="pl-2 font-weight-bold">{{$t('Carrello')}}</span>
                            </h3>
                        </div>

                        <span>{{$t('Articoli')}}: {{ totqty }}</span>

                        <div v-for="(p, i) in prods" :key="p.Id" class="d-flex flex-wrap mt-3 p-2 rounded bordo-oro mx-auto justify-content-end">
                            <div class="col">
                                <div class="row">
                                    <div v-if="p.Family != 'Ricamo'" class="col-1 pr-155" >
                                        <img class="rounded" :src="p.img" width="150">
                                    </div>
                                    <div class="col">
                                        <span class="font-weight-bold">{{p.Name}}</span>
                                        <span v-if="p.Possibilita_Ricamo__c && p.testoRicamo && p.testoRicamo.length > 0">
                                            <br>Testo: <input v-model="p.testoRicamo" disabled/>
                                        </span>
                                        <span v-if="p.taglia != '' && p.taglia && p.taglia != '*'">{{$t('Taglia')}} {{p.taglia}}</span>
                                        <span v-if="p.numero != '' && p.numero">{{$t('Numero')}} {{p.numero}}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col mt-3">
                                <div class="d-flex flex-wrap justify-content-end">
                                    <div v-if="p.Family != 'Ricamo'" class="col mx-0">
                                        <ul class="pagination">
                                            <li class="almablue align-content-center text-white border border-dark rounded-left px-2" :class="{'disabled': ( p.qty === 1 )}" @click="alterQty(p, -1)">-</li>
                                            <li class="align-content-center px-2 border">{{p.qty}}</li>
                                            <li class="zIndex3 active almablue align-content-center text-white border border-dark rounded-right px-2" :class="{'disabled': ( p.qty === 10 || p.Max_Acquisti_Per_Ordine__c == p.qty )}" @click="alterQty(p, 1)">+</li>
                                        </ul>
                                    </div>
                                    
                                    <div class="col fit-content">
                                        <span class="font-weight-bold">{{p.Prezzo__c * p.qty | amount}}</span>
                                    </div>

                                    <div class="col" v-if="p.Family != 'Shopper'">
                                        <button type="button" @click="deleteProd(i)" class="btn btn-danger btn-sm float-right">
                                            <i class="bi bi-trash3-fill"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col">
                                <div class="row">
                                    <div class="col-1 pr-155">
                                        <img class="rounded" :src="p.img" width="150">
                                    </div>
                                    <div class="col">
                                        <span class="font-weight-bold">{{p.Name}}</span>
                                        <span v-if="p.taglia != '' && p.taglia && p.taglia != '*'">{{$t('Taglia')}} {{p.taglia}}</span>
                                        <span v-if="p.numero != '' && p.numero">{{$t('Numero')}} {{p.numero}}</span>
                                    </div>
                                </div>
                            </div> -->

                            <!-- <div class="col mt-3">
                                <div class="d-flex flex-wrap justify-content-end">
                                    <div class="col mx-0">
                                        <ul class="pagination">
                                            <li class="almablue align-content-center text-white border border-dark rounded-left px-2" :class="{'disabled': ( p.qty === 1 )}" @click="alterQty(p, -1)">-</li>
                                            <li class="align-content-center px-2 border">{{p.qty}}</li>
                                            <li class="zIndex3 active almablue align-content-center text-white border border-dark rounded-right px-2" :class="{'disabled': ( p.qty === 10 || p.Max_Acquisti_Per_Ordine__c == p.qty )}" @click="alterQty(p, 1)">+</li>
                                        </ul>
                                    </div>
                                    
                                    <div class="col fit-content">
                                        <span class="font-weight-bold">{{p.Prezzo__c * p.qty | amount}}</span>
                                    </div>

                                    <div class="col" v-if="p.Family != 'Shopper'">
                                        <button type="button" @click="deleteProd(i)"
                                        class="btn btn-danger btn-sm float-right">
                                            <i class="bi bi-trash3-fill"></i>
                                        </button>
                                    </div>
                                </div>
                            </div> -->
                                <!-- solo desktop -->
                                <!-- <div class="col-2 d-none d-md-block d-lg-block">
                                    <ul class="pagination">
                                        <li class="almablue align-content-center text-white border border-dark rounded-left px-2" :class="{'disabled': ( p.qty === 1 )}" @click="alterQty(p, -1)">-</li>
                                        <li class="align-content-center px-2 border">{{p.qty}}</li>
                                        <li class="zIndex3 active almablue align-content-center text-white border border-dark rounded-right px-2" :class="{'disabled': ( p.qty === 10 || p.Max_Acquisti_Per_Ordine__c == p.qty )}" @click="alterQty(p, 1)">+</li>
                                    </ul>
                                </div>

                                
                                <div class="col-3 d-none d-md-block d-lg-block fit-content">
                                    <span class="font-weight-bold">{{p.Prezzo__c * p.qty | amount}}</span>
                                </div>

                                
                                <div v-if="p.Family != 'Shopper'" class="col-1 d-none d-md-block d-lg-block">
                                    <button
                                        type="button" @click="deleteProd(i)"
                                        class="btn btn-danger btn-sm float-right"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                        </svg>
                                    </button>
                                </div> -->
                                <!-- fine solo desktop -->

                                <!-- solo mobile -->
                                <!-- <div class="col-2 d-block d-sm-none">
                                    <span class="float-right font-weight-bold">{{p.Prezzo__c * p.qty | amount}}</span>
                                </div>

                                <div class="col-2 d-block d-sm-none pt-2">
                                    {{$t('Qta')}}:
                                </div>

                                <div :class="p.Max_Acquisti_Per_Ordine__c == p.qty ? 'col-3 d-block d-sm-none pt-1 pe-none':'col-3 d-block d-sm-none pt-1'">
                                    <ul class="pagination pagination-sm">
                                        <li class="almablue align-content-center text-white border border-dark rounded-left px-2" :class="{'disabled': p.qty === 1}" @click="alterQty(p, -1)">-</li>
                                        <li class="align-content-center px-2 border">{{p.qty}}</li>
                                        <li class="active almablue align-content-center text-white border border-dark rounded-right px-2" :class="{'disabled': p.qty === 10}" @click="alterQty(p, 1)">+</li>
                                    </ul>
                                </div>

                                <div v-if="p.Family != 'Shopper'" class="col-3 d-block d-sm-none">
                                    <button type="button" @click="deleteProd(i)" class="btn btn-danger float-right">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                        </svg>
                                    </button>
                                </div> -->
                                <!-- fine solo mobile -->
                            <!-- </div> -->
                        </div>
                    </div>
                </div>

                <div class="col-md-4 col-lg-4 col-xl-4">
                    <div>
                        <div v-if="shoppers.length > 0" class="d-flex justify-content-between align-items-center d-none d-md-block d-lg-block mt-5 mt-lg-0 mt-md-0 border-bottom mb-3">
                            <h3 class="font-weight-bold">{{$t('vuoiUnaBusta')}}</h3>
                        </div>
                        <div v-if="shoppers.length > 0" class="form-check mb-5">
                            <input class="form-check-input ml-2" type="radio" name="radioButton" id="radio0" value="radio0" @click="addSacchetto('')" checked>
                            <label class="form-check-label ml-5 pb-2" for="radio0">{{$t('noBusta')}}</label>
                            <div v-for="s in shoppers" :key="s.Id" >
                                <input class="form-check-input ml-2" type="radio" name="radioButton" :id="'radio'+s.Id" :value="'radio'+s.Id" @click="addSacchetto(s)">
                                <label class="form-check-label ml-5 pb-2" :for="'radio'+s.Id">
                                    {{ s.Name }}
                                </label>
                                <span class="float-right font-weight-bold">{{s.Prezzo__c | amount}}</span>
                            </div>
                        </div>

                        <!--
                            <stripe-checkout
                                ref="checkoutRef" mode="payment" :pk="publishableKey" @loading="v => loading = v"
                                :line-items="{price: $store.state.totaleEcommerce, quantity: 1}"
                                :success-url="successURL" :cancel-url="cancelURL"
                            />
                        -->

                        <div class="border-bottom mb-3">
                            <h3 class="font-weight-bold">{{$t('Riepilogo')}}</h3>
                        </div>
                        
                        <div class="d-flex justify-content-between information">
                            <span>{{$t('Importototale')}}</span>
                            <span>{{totprice | amount}}</span>
                        </div>

                        <button class="btn btn-block justify-content-center mt-3" :class="bloccaPagamento ? 'btn-secondary':'btn-primary'" 
                        type="button" @click="buy" :disabled="bloccaPagamento">
                            <span class="font-weight-bold text-uppercase">{{$t('Acquistaora')}}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- fine carrello -->
    </div>
</template>

<script>
    //import { StripeCheckout } from '@vue-stripe/vue-stripe'
    import numeral from 'numeral'

    export default {
        name: 'ShopCart',
        components: {
            //StripeCheckout
        },
        props: {
            imgs: Object,
            shoppers: Array,
            ricamo: Object,
        },
        data: function() {
            return {
                prods: [],

                totprice: 0,
                totqty:   0,
            }
        },
        computed: {
            bloccaPagamento: function() {
                if(this.prods.length == 0) return true
                if(this.totprice == 0) return true
                return false
            }
        },
        filters: {
            amount: d => numeral(d).format('0,0[.]00 $')
        },
        mounted: function() {
            this.totprice = parseFloat(this.$store.state.totaleEcommerce)
            this.successURL = window.location.hostname
            this.cancelURL  = window.location.hostname + '/acquisti'

            this.getProds()
        },
        methods: {
            getProds: function() {
                // this.prods = this.$store.state.carrelloEcommerce
                this.$store.dispatch('recuperaCarrello')
                this.prods = this.$store.state.carrelloEcommerce
                console.log('getProds', this.prods)
                this.totqty = 0

                var inserisciRicamo = false
                this.prods.forEach(p => {
                    if(p.testoRicamo) {
                        inserisciRicamo = true
                        this.ricamo.qty = 1
                        this.ricamo.testoRicamo = p.testoRicamo
                        this.ricamo.Description = `${this.ricamo.Name} "${p.testoRicamo}" su ${p.Name} (${p.ProductCode})`
                    }
                    if(p.Family == 'Ricamo') inserisciRicamo = false // significa che l'ho già inserito
                    p.qtyShow = false
                    this.totqty += p.qty

                    this.$store.dispatch('getImage', {id:p.Id, limit:true})
                    .then(res => {
                        console.log('getImage res', res)
                        p.img=res[0];
                    })

                    // p.img=this.imgs[p.Id];
                })
                if(inserisciRicamo) {
                    this.totprice += this.ricamo.Prezzo__c
                    this.prods.push(this.ricamo)
                    this.$store.commit('SET_CARRELLO', this.prods)
                }
            },
            deleteProd: function(index) {
                console.log('deleteProd',this.prods[index])
                var ptot = this.prods[index].qty * this.prods[index].Prezzo__c
                this.totqty   -= this.prods[index].qty
                this.totprice -= ptot

                if(this.prods[index].Family && this.prods[index].Family == 'Ricamo') {
                    this.prods.forEach( p => {
                        if(p.possibilitaRicamo && p.testoRicamo != '') p.testoRicamo = ''
                    })
                }
                console.log('this.prods[index].testoRicamo',this.prods[index].testoRicamo)
                // var rimuoviRicamo = false
                // if(this.prods[index].testoRicamo != '' && this.prods[index].testoRicamo != undefined) rimuoviRicamo = true

                this.prods.splice(index, 0)
                this.$store.dispatch('removeProd', index)
                // if(rimuoviRicamo) this.rimuoviRicamo()
            },
            rimuoviRicamo(){
                for(var i = 0; i <= this.prods.length; i++) {
                    if(this.prods[i].Family == 'Ricamo') this.deleteProd(i)
                }
            },
            pagaOra: function() {
                this.$store.dispatch('pagaOra', 'Interno').then(resp => {
                    console.log(resp)
                    this.$emit('ordinato')
                })
            },
            alterQty: function(p, alt) {
                p.qty += alt

                this.totprice += (alt * (p.Prezzo__c ? p.Prezzo__c : 0))
                this.totqty   += alt

                this.$store.commit('SET_CARRELLO', this.prods);
            },
            addSacchetto(shopper) {
                var prodotti = []
                for(var i = 0; i < this.prods.length; i++) {
                    if(this.prods[i].Family == 'Shopper') {
                        this.totprice -= this.prods[i].Prezzo__c * this.prods[i].qty
                    } else prodotti.push(this.prods[i])
                }
                this.prods = prodotti
                if(shopper != '') {
                    shopper['qty'] = 1
                    this.prods.push(shopper)
                    this.totprice += shopper.Prezzo__c
                }
                this.$store.state.carrelloEcommerce = prodotti
            },
            buy: function() { this.$router.push({ name: 'EcommerceRiepilogo' }) }
        }
    }
</script>

<style>
    .bordo-oro { border: 1px solid #d29500 !important; }

    .pr-155 { padding-right: 155px; }

    .zIndex3 { z-index: 3; }

    .fit-content { max-width: fit-content }
</style>