<template>
  <div class="home" v-if="$store.getters.logged">
    <navbar :menulist="$store.state.mainmenu" @goto="from = undefined"></navbar>
    <herobig :title="$t('title')"></herobig>

    <h2 class="welcome">{{ showIntervento ? $t('NewTicket') : $t('subtitleInterventi') }}</h2>

    <boxcontainer>
      <div v-if="!showIntervento">
        <div class="row" v-if="casi.length">
          <div class="col">
            <almatable :cols="cinterventi" :rows="casi" @upload="uploadCaso($event)"></almatable>
          </div>
        </div>

        <div class="row">
          <div class="col text-center">
            <button class="btn btn-primary" @click.prevent="newCase">{{ $t('NuovaRichiesta') }}</button>
          </div>
        </div>
      </div>

      <div v-if="showIntervento">
        <form class="needs-validation" novalidate>
          <div class="form-row mb-3">
            <div class="col">
              <select v-if="!enableNewCategories" class="custom-select" :class="{ 'text-muted': rt == '' }" v-model="rt">
                <option disabled value="">{{ $t('EnterCat') }}</option>
                <option v-for="cat in categorie" :key="cat.id" :value="cat.id">{{ cat.name }}</option>
              </select>
              <!-- CATEGORIA -->
              <select v-if="enableNewCategories" maxlength="100" class="custom-select" v-model="categoria" v-on:change="resetSottocategoria" required>
                <option :value="null" disabled selected hidden>{{ $t('EnterCat') }}</option>
                <!-- <option value="Colloqui con Direttore Didattico">{{ $t('ColloquiDirettoreDidattico') }}</option> -->
                <option value="Assenze">{{ $t('Assenze') }}</option>
                <option value="Certificazione medica obbligatoria">{{ $t('Medico') }}</option>
                <option value="Didattica e Vita residenziale">{{ $t('DidatticaVitaResidenziale') }}</option>
                <option value="Esami finali">{{ $t('EsamiFinali') }}</option>
                <option value="Housing">Housing</option>
                <option value="Hyperplanning e Webapp">{{ $t('HyperplanningWebapp') }}</option>
                <option value="Iscrizioni">{{ $t('Iscrizioni') }}</option>
                <option value="Richieste certificati">{{ $t('RichiesteCertificati') }}</option>
                <option value="Segnalazione armadietto">{{ $t('SegnalazioneArmadietto') }}</option>
                <option value="Stage">Stage</option>
              </select>
            </div>
          </div>

          <div class="form-row mb-3">
            <div class="col">
              <select v-if="enableNewCategories && categoria == null" maxlength="100" class="custom-select" required>
                <option :value="null" disabled selected hidden>{{ $t('InserisciSottocategoria') }}</option>
              </select>

              <select v-if="enableNewCategories && categoria == 'Assenze'" v-model="sottocategoria" maxlength="100" class="custom-select" required>
                <option :value="null" disabled selected hidden>{{ $t('InserisciSottocategoria') }}</option>
                <!-- <option value="Check firma registro">{{ $t('CheckFirmaRegistro') }}</option> -->
                <option value="Invio certificato medico per giustificazione assenze">{{ $t('InvioCertificatoMedicoPerGiustificazioneAssenze') }}</option>
                <option value="Richiesta monte ore assenze attuale">{{ $t('RichiestaMonteOreAssenzeAttuale') }}</option>
                <option value="Segnalazione problema assenza (assenza inserita per errore/giustifica non inserita)">{{ $t('SegnalazioneProblemaAssenza') }}</option>
                <option value="Altro">{{ $t('Altro') }}</option>
              </select>

              <select v-if="enableNewCategories && categoria == 'Didattica e Vita residenziale'" v-model="sottocategoria" maxlength="100" class="custom-select" required>
                <option :value="null" disabled selected hidden>{{ $t('InserisciSottocategoria') }}</option>
                <option value="Calendario corsi e festività">{{ $t('CalendarioCorsiFestivita') }}</option>
                <option value="Colloqui con corpo docente">{{ $t('ColloquiCorpoDocente') }}</option>
                <option value="Info Date corsi">{{ $t('InfoDateCorsi') }}</option>
                <option value="Info orario di arrivo">{{ $t('InfoOrarioArrivo') }}</option>
                <option value="Masterclass e attività a scelta">{{ $t('MasterclassAttivitaScelta') }}</option>
                <option value="Materiali didattici">{{ $t('MaterialiDidattici') }}</option>
                <option value="Primo giorno">{{ $t('subtitlePrimoGiorno') }}</option>
                <option value="Problematica iscrizione masterclass e attività a scelta">{{ $t('ProblematicaIscrizioneMasterclassAttivita') }}</option>
                <option value="Verifiche - Dettagli Voto diploma">{{ $t('VerificheVotoDiploma') }}</option>
                <option value="Verifiche - Problemi visualizzazione voto verifiche">{{ $t('VerificheVisualizzazioneVoto') }}</option>
                <option value="Altro">{{ $t('Altro') }}</option>
              </select>

              <select v-if="enableNewCategories && categoria == 'Esami finali'" v-model="sottocategoria" maxlength="100" class="custom-select" required>
                <option :value="null" disabled selected hidden>{{ $t('InserisciSottocategoria') }}</option>
                <!-- <option value="Problematica apertura ticket su Webapp">{{ $t('ProblematicaTicketWebapp') }}</option> -->
                <option value="Informazioni per stesura Tesi">{{ $t('InfoStesuraTesi') }}</option>
                <option value="Informazioni su programma delle prove d'esame">{{ $t('InfoProveEsame') }}</option>
                <option value="Informazioni svolgimento cerimonie dei diplomi">{{ $t('InfoCerimonieDiplomi') }}</option>
                <option value="Modalità di consegna materie prime">{{ $t('ModConsegnaIngr') }}</option>
                <option value="Modalità di consegna attrezzature">{{ $t('ModConsegnaAttr') }}</option>
                <option value="Prenotazione sessione o richiesta spostamento nuova sezione">{{ $t('PrenotazioneSpostamentoNuovaSezione') }}</option>
                <option value="Problematiche attrezzature">{{ $t('ProblematicheAttrezzature') }}</option>
                <option value="Problematiche ingredienti">{{ $t('ProblematicheIngredienti') }}</option>
                <option value="Problemi di caricamento documenti di esame">{{ $t('ProbCaricaDocEsami') }}</option>
                <option value="Altro">{{ $t('Altro') }}</option>
              </select>

              <select v-if="enableNewCategories && categoria == 'Housing'" v-model="sottocategoria" maxlength="100" class="custom-select" required>
                <option :value="null" disabled selected hidden>{{ $t('InserisciSottocategoria') }}</option>
                <option value="Segnalazione guasti">{{ $t('SegnalazioneGuasti') }}</option>
                <option value="Richiesta di uscita housing anticipata/posticipata a fine corso">{{ $t('RichiestaUscitaHousing') }}</option>
                <option value="Sacchi spazzatura">{{ $t('SacchiSpazzatura') }}</option>
                <option value="Segnalazione problematiche varie">{{ $t('SegnalazioniVarie') }}</option>
                <option value="Pagamenti Housing">{{ $t('PagamentiHousing') }}</option>
                <option value="Altro">{{ $t('Altro') }}</option>
              </select>

              <select v-if="enableNewCategories && categoria == 'Hyperplanning e Webapp'" v-model="sottocategoria" maxlength="100" class="custom-select" required>
                <option :value="null" disabled selected hidden>{{ $t('InserisciSottocategoria') }}</option>
                <option value="Problemi accesso Webapp">{{ $t('ProblemiAccessoWebapp') }}</option>
                <option value="Problemi di accesso Hyperplanning (credenziali errate,...)">{{ $t('ProblemiVariHP') }}</option>
                <option value="Richiesta credenziali app MyAlma Life">{{ $t('RichiestaCredenzialiApp') }}</option>
                <option value="Richiesta link per accesso nuova base dati Hyperplanning">{{ $t('RichiestaLinkHP') }}</option>
                <option value="Altro">{{ $t('Altro') }}</option>
              </select>

              <select v-if="enableNewCategories && categoria == 'Iscrizioni'" v-model="sottocategoria" maxlength="100" class="custom-select" required>
                <option :value="null" disabled selected hidden>{{ $t('InserisciSottocategoria') }}</option>
                <option value="Richieste info pagamenti">{{ $t('InfoPagamenti') }}</option>
                <option value="Altro">{{ $t('Altro') }}</option>
              </select>

              <select v-if="enableNewCategories && categoria == 'Certificazione medica obbligatoria'" v-model="sottocategoria" maxlength="100" class="custom-select" required>
                <option :value="null" disabled selected hidden>{{ $t('InserisciSottocategoria') }}</option>
                <option value="Problemi di caricamento documentazione app MyAlma Life">{{ $t('ProblCaricaDocMedico') }}</option>
                <option value="Richiesta modello di certificato anamnestico">{{ $t('RichiestaCertAnamnestico') }}</option>
                <option value="Altro">{{ $t('Altro') }}</option>
              </select>

              <select v-if="enableNewCategories && categoria == 'Richieste certificati'" v-model="sottocategoria" maxlength="100" class="custom-select" required>
                <option :value="null" disabled selected hidden>{{ $t('InserisciSottocategoria') }}</option>
                <option value="Frequenza">{{ $t('Frequenza') }}</option>
                <option value="HACCP e SICUREZZA">{{ $t('HaccpSicurezza') }}</option>
                <option value="Richiesta certificato di iscrizione (es. per accesso a finanziamenti)">{{ $t('RichiestaCertIscrizione') }}</option>
                <option value="Richiesta certificato frequenza fase residenziale">{{ $t('RichiestaCertFrequenza') }}</option>
                <option value="Richiesta certificato sostitutivo del diploma">{{ $t('RichiestaCertSostitutivo') }}</option>
                <option value="Strutturato">{{ $t('Strutturato') }}</option>
                <option value="Altro">{{ $t('Altro') }}</option>
              </select>

              <select v-if="enableNewCategories && categoria == 'Stage'" v-model="sottocategoria" maxlength="100" class="custom-select" required>
                <option :value="null" disabled selected hidden>{{ $t('InserisciSottocategoria') }}</option>
                <option value="Pre inizio stage - Colloqui con Direttore Didattico">{{ $t('ColloquiDirettorePreStage') }}</option>
                <option value="Pre inizio stage - Richiesta colloquio per info dopo consegna stage">{{ $t('RichColloquioPreStage') }}</option>
                <option value="Problematiche durante lo stage - Compilazione Dossier">{{ $t('ProblStageCompilazioneDossier') }}</option>
                <option value="Problematiche durante lo stage - Mansionario">{{ $t('ProblStageMansionario') }}</option>
                <option value="Problematiche durante lo stage - Monte ore">{{ $t('ProblStageMonteOre') }}</option>
                <option value="Problematiche durante lo stage - Altro">{{ $t('ProblStageAltro') }}</option>
              </select>

              <select v-if="enableNewCategories && categoria == 'Segnalazione armadietto'" v-model="sottocategoria" maxlength="100" class="custom-select" required>
                <option :value="null" disabled selected hidden>{{ $t('InserisciSottocategoria') }}</option>
                <option value="Armadietto Guasto">{{ $t('Guasto') }}</option>
                <option value="Altro">{{ $t('Altro') }}</option>
              </select>
            </div>
          </div>

          <div class="form-row mb-3">
            <div class="col">
              <input
                maxlength="100" v-model="subject" required type="text"
                class="form-control" :placeholder="$t('InserisciTitolo')"
              />
            </div>
          </div>

          <div class="form-row mb-3">
            <div class="col">
              <textarea
                maxlength="100" v-model="description" required type="text"
                class="form-control" :placeholder="$t('InserisciDescrizione')"
              />
            </div>
          </div>

          <div class="form-row">
            <div class="col text-center">
              <button v-if="!enableNewCategories" class="btn btn-primary" :disabled="!subject || !description || !rt" @click.prevent="saveCase">{{ $t('Invia') }}</button>
              <button v-else class="btn btn-primary" :disabled="!subject || !description || !categoria || !sottocategoria" @click.prevent="saveCase">{{ $t('Invia') }}</button>
              &nbsp;
              <button class="btn btn-outline-secondary" @click.prevent="cancelCase">{{ $t('Annulla') }}</button>
            </div>
          </div>
        </form>
      </div>

      <modaluploadMulti :show="uploadShow" :id="idCaso" @submit="uploadDone" @cancel="uploadCancel"></modaluploadMulti>
    </boxcontainer>
    
    <footerline></footerline>
  </div>
</template>

<script>
  // @ is an alias to /src
  import navbar           from '@/components/navbar.vue'
  import herobig          from '@/components/herobig.vue'
  import boxcontainer     from '@/components/boxcontainer.vue'
  import footerline       from '@/components/footerline.vue'
  import almatable        from '@/components/almatable.vue'
  import modaluploadMulti from '@/components/modaluploadMulti.vue'

  export default {
    name: 'Interventi',
    components: {
      navbar,
      herobig,
      boxcontainer,
      footerline,
      almatable,
      modaluploadMulti
    },
    props: {
      from: String
    },
    data: function () {
      return {
        idCaso:         '',
        cinterventi:    [],
        casi:           [],
        uploadShow:     false,
        showIntervento: false,
        categorie:      [],

        // Nuovo caso
        rt:          '',
        subject:     '',
        description: '',
        categoria:   null,
        sottocategoria: null
      }
    },
    mounted: function() {
      this.$store.dispatch('getCategorieCasi').then(() => {
        Object.entries(this.$store.state.casicat).forEach(([id, name]) => {
          this.categorie.push({ id: id, name: name })

          if (this.from != undefined && name == this.from) this.rt = id
        })
      })

      console.log('studente = ' + this.$store.getters.housing)

      /* if (!this.$store.getters.housing) {
        console.log('idHousing: exit')
        this.$router.push({ name: 'Home' })
        return
      } */

      this.cinterventi.push(
        { title: this.$i18n.t('Data'),      name: 'CreatedDate', filter: 'datetime' },
        { title: this.$i18n.t('Categoria'), name: 'RType'                           },
        { title: this.$i18n.t('Stato'),     name: 'Status'                          },
        { title: this.$i18n.t('Oggetto'),   name: 'Subject'                         },
        { title: this.$i18n.t('Allegato'),  name: 'Upload'                          }
      )

      this.$store.dispatch('getCasi').then(response => {
        console.log('casi')
        console.log(response)

        if (response) this.getCategorie(response)
      })
    },
    computed: {
      enableNewCategories: function() {
        return true
      }
    },
    methods: {
      getCategorie: function(casi) {
        casi.forEach(caso => {
          caso['upload'] = !(caso.Status == 'Closed')
          if(caso.Categorie__c) caso['RType'] = caso.Categorie__c
          else caso['RType'] = caso.RecordType.DeveloperName
          this.casi.push(caso)
        }
          // this.casi.push({
          //   ...caso,
          //   upload: !(caso.Status == 'Closed'),
          //   RType:  caso.RecordType.DeveloperName
          // })
        )
        console.log('casi dopo getCategorie', casi)
      },

      newCase: function() {
        this.resetCase()
        this.showIntervento = true
      },
      resetSottocategoria: function() {
        this.sottocategoria = null
      },
      saveCase: function() {
        var c = {
          AccountId:          this.$store.state.userprofile.Id,
          Opportunita__c:     this.$store.state.idHousing,
          Subject:            this.subject,
          Description:        this.description,
          RecordTypeId:       this.rt,
          Origin:             'webapp'
        }
        console.log('caso',c)

        if (this.enableNewCategories) {
          c.Opportunita__c = this.$store.state.idOppty
          if (this.$store.state.idHousing != '' && this.categoria == 'Housing') c.Opportunita__c = this.$store.state.idHousing
          c['Categorie__c'] = this.categoria
          c['Sottocategoria__c'] = this.sottocategoria
          for(var recordType of this.categorie) {
            if(recordType.name == 'Housing') c.RecordTypeId = recordType.id
          }
        }
        console.log('caso',c)

        this.$store.dispatch('setCaso', c).then(() => {
          this.resetCase()
          this.showIntervento = false

          this.casi = []
          this.rt   = {}

          this.$store.dispatch('getCasi').then(response => {
            console.log('casi')
            console.log(response)

            if (response) this.getCategorie(response)
          })
        })
      },
      cancelCase: function() {
        this.resetCase()
        this.showIntervento = false
      },
      resetCase: function() {
        this.subject = ''
        this.description = ''
        this.categoria = null
        this.sottocategoria = null
      },
      
      uploadCaso: function(idCaso) {
        this.idCaso = idCaso
        this.uploadShow = true
      },
      uploadDone: function() {
        this.uploadShow = false
      },
      uploadCancel: function() {
        this.uploadShow = false
      }
    }
  }
</script>