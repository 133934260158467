<template>

  <div v-if="show" style="display:block" class="modal fade" v-bind:class="{'show': show}" id="uploadDocument" tabindex="-1" role="dialog" aria-labelledby="uploadDocument" >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="uploadDocument">{{$t('CaricaDocumento')}}</h5>
          <button type="button" class="close" aria-label="Close" v-on:click.prevent="$emit('cancel')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <label>{{$t('ScegliFile')}}
            <input type="file" id="file" ref="file" v-on:change="fileUpload" name="File"/>
          </label>
          <div v-if="submitOk">
            <!-- <label>{{$t('Filecaricare')}}</label><br/>{{this.myfile.name}}<br/> -->
            <!-- <label>{{$t('Dimensioni')}}</label> {{this.myfile.size}}<br/> -->
            <label>{{$t('Dimensioni')}}</label> {{this.myfileMB}} MB<br/>
            <p>{{ $t('Limite') }}</p>
            <div v-if="erroredimensioni" class="alert alert-danger" role="alert">{{$t('DimensioniEccessive')}}</div>
            <div v-if="uploading" class="progress">
              <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" v-bind:aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100" v-bind:style="'width: '+progress+'%'"></div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button v-if="uploading" class="btn btn-primary" type="button" disabled>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="sr-only">Loading...</span>
          </button>          
          <button v-if="!uploading" type="button" class="btn btn-primary" v-on:click.prevent="doUpload" v-bind:disabled="!submitOk || erroredimensioni">{{$t('Carica')}}</button>
          <button v-if="!uploading" type="button" class="btn btn-secondary" v-on:click.prevent="$emit('cancel')" >{{$t('Annulla')}}</button>
        </div>
      </div>
    </div>
    <div v-if="show" style="z-index:-1" class="modal-backdrop fade show"></div>
  </div>

</template>

<script>
  import apiclient from '@/apiclient.js'

  const openpgp = require('openpgp')

  export default {
    name: 'modalupload',
    props: {
      show: Boolean,
      id: String,
      tipoFile: String,
      certkey: String
    },
    data: function () {
      return {
        submitOk: false,
        myfile: {},
        fileData: '',
        progress: 0,
        uploading: false,
        myfileMB: 0,
        fileSizeWithBuffer: 0,
        bufferSize: 0
      }
    },
    computed: {
      erroredimensioni: function() {
        if (!this.myfile) return false;
        if (this.myfile.size<=5242880 && this.bufferSize <=5242880) return false;
        return true;
      },
    },
    methods: {
      fileUpload: function() {
        if (this.$refs.file.files.length>0) {
          this.submitOk=true;
          this.myfile=this.$refs.file.files[0];
          this.myfileMB = this.myfile.size / 1000000
          this.bufferSize = 0
          console.log(this.myfile);
          const reader = new FileReader();
          reader.onload = this.doReadFile
          reader.readAsDataURL(this.myfile);
        } else {
          console.log('nessun file')
          this.submitOk = false
        }
      },
      uploadProgress: function(ev) {
        console.log('uploadProgress');
        console.log('ev',ev);
        this.fileSizeWithBuffer = ev.total
        console.log('this.fileSizeWithBuffer', this.fileSizeWithBuffer)
        this.progress = ev.loaded / ev.total * 100;        
      },
      doReadFile: function (e) {
        console.log('doReadFile modalupload');
        console.log(e);
        this.fileData=e.target.result.split(',')[1];
      },
      doUpload: async function() {
        console.log('--> modalupload.vue doUpload')
        this.uploading=true;
        var self=this;
        var tipo = this.tipoFile? this.tipoFile : '';
        console.log('tipoFile: '+this.tipoFile)

        if (tipo === 'cryptDoc') {
          console.log('is crypt')
          tipo = ''
          
          var pk   = await openpgp.readKey({ armoredKey: this.certkey }),
              temp = await openpgp.createMessage({ text: this.fileData }).then(res => openpgp.encrypt({ message: res, encryptionKeys: pk }))
          
          this.fileData = btoa(temp)
        }

        this.$nextTick(function () {
          self.$store.dispatch('loginbyid').then(function() {
            console.log('doUpload');
            var data={ username: self.$store.state.connection.username, callname:'uploadDocument', crypt: self.$store.state.connection.userdata,
              parentId: self.id,  filename: self.myfile.name,  payload: self.fileData, tipoFile: tipo
            };
            console.log('payload:self.fileData',self.fileData)

            apiclient.getcandidatura( self.$store.state.connection, data, self.uploadProgress)
            .then( function(resp) {
              console.log('doUpload:');
              console.log(resp)
              self.$emit('submit', resp);
              self.myfile={}; // questo azzeramento fa apparire la scritta DimensioniEccessive se uno riapre il modale
              self.fileData='';
              self.progress=0;
              self.uploading=false;
              self.submitOk = false
            }).catch(err => { 
              console.log('getcandidatura, callname: uploadDocument, err ',err)
              self.uploading = false
              self.progress = 0
              self.bufferSize = self.fileSizeWithBuffer
             });
          });
        });
      }
    }
  }
</script>

<style scoped>
</style>